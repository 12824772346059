import React from "react"

import Layout from "../components/layout"
import smartCity from "../images/smart-city.svg"
import safety from "../images/safety.svg"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title="About Us" />
    <div className="flex flex-row flex-wrap items-center space-between bg-gray-200 mb-20 py-20">
      <div className="w-full md:w-1/2 px-16">
        <h1 className="py-10 uppercase text-3xl font-bold">About Us</h1>
        <p>
          CM Teleservices is the leading telecom service providing company with
          its main office at Kathamandu, which has been providing its services
          to network service provider with its high technological competence for
          5 years. Its core mission is to be a part on digital transformation of
          Nepal.
        </p>
      </div>
      <div className="w-full md:w-1/2">
        <figure className="">
          <img src={smartCity} alt="5G is our vision" />
        </figure>
      </div>
    </div>
    <div className="flex flex-row flex-wrap justify-between items-center px-6 md:px-16 mb-20">
      <div className="w-full lg:w-1/2 lg:pr-20">
        <h3 className="font-bold text-3xl capitalize">Our Mission</h3>
        <p className="my-5">
          To excel and contribute in telecommunication sector by providing
          international standard of service and winning market and stakeholders
          trust.
        </p>
        <h3 className="font-bold text-3xl capitalize">Our Vision</h3>
        <p className="my-5">
          To be an efficient and leading telecom service provider in the market.
        </p>
      </div>
      <div className="w-full lg:w-1/2">
        <h3 className="font-bold text-3xl">Our Core Values</h3>
        <p className="my-5">We work within our values</p>
        <div className="grid grid-flow-row grid-cols-1 md:grid-cols-2 col-gap-12 row-gap-10">
          <div className="">
            <h4 className="font-medium text-xl pb-2">Honest and Integrity</h4>
            <p className="">
              Our customer trust us with their project and its our duty to keep
              the promise. We work with honor and truthfulness to keep our
              words.
            </p>
          </div>
          <div className="">
            <h3 className="font-medium text-xl pb-2">
              Customer Centric Approach
            </h3>
            <p className="">
              Customers are heart of our business and we build and nurture
              relationships with potential and existing customers.
            </p>
          </div>
          <div className="">
            <h3 className="font-medium text-xl mb-2">Professionalism</h3>
            <p className="">
              We are focused and we listen. Our customers want the best and we
              strive to deliver the best.
            </p>
          </div>
          <div className="">
            <h3 className="font-medium text-xl mb-2">Responsibility</h3>
            <p className="">
              We respect our customers time, data and privacy and with all due
              we feel respnsible to protect them.
            </p>
          </div>
          <div className="">
            <h3 className="font-medium text-xl mb-2">Teamwork</h3>
            <p className="">
              Our relation with customer is like a team. We work together to
              deliver our best
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-2 col-gap-32 row-gap-10 items-center bg-gray-200 py-16 px-6 md:px-16 ">
      <div class="w-full">
        <h3 className="font-bold text-3xl">EHS Policy</h3>
        <p className="text-xl py-2">What We Care Most</p>
        <p>
          CM Teleservices carries all activities in manner that minimizes
          impacts on environment. We conduct assigned duties in a manner that
          complies with applicable environmental laws and regulations.
        </p>
        <p>
          At CM Teleservices, we provide a safe and healty work place. To ensure
          health and safety we
        </p>

        <ul className="list-disc pl-10">
          <li>
            make arrangements for implementing the health and safety measures
            identified as necessary by the risk assessment;
          </li>
          <li>appoint competent people to implement the arrangements;</li>
          <li>set up emergency procedures;</li>
          <li>provide clear information and training to employees;</li>
          <li>
            work together with other employers sharing the same workplace.
          </li>
        </ul>
      </div>
      <div class="w-full">
        <figure>
          <img src={safety} alt="ehs" className="w-full" />
        </figure>
      </div>
    </div>
  </Layout>
)

export default AboutPage

// Performed maximum number of microwave installation, comissioning
//                 along with traffice shifting in EDR
//                 Performed installation and comissioning of Cell On Wheels (COW)
//                 BTS of Ncell
//                 Performed BTS installation of Ncell, NTC, Hello and Smartel
//                 through out the nation
//                 Performed site survey, acquisition and civil foundation of Ncell
//                 and NTC sites
